<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-04-03 10:34:36
 * @LastEditTime: 2023-07-04 14:22
 * @Descripttion: 【作业考试-题库】题库中心
-->
<style lang="scss" scoped>
.question-bank {
  @include innerPage;
  @include pageHead(-10px);
  @include pageFoot;

  .page-head {
    .search-form {
      width: 78.67%;
      min-width: 970px;

      &-wrapper {
        width: 100%;
        margin-top: 16px;
      }
    }

    .el-form-item {
      width: 16%;
      max-width: 268px;
      min-width: 164px;
    }
  }

  .page-inner {
    width: 100%;
    height: calc(100% - 202px);
    margin-top: 40px;
  }

  .questions {
    width: calc(100% + 20px);
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    flex-wrap: wrap;
    @include flexBox(flex-start, flex-start);

    &-wrapper {
      width: 100%;
      height: calc(100% - 74px);

      &.no-data {
        width: 100%;
        height: calc(100% - 104px);
        background: #fff;
        border-radius: 10px;
        margin: 0 auto 30px;
      }
    }

    &-item {
      width: calc((100% - 108px) / 3);
      height: 318px;
      margin-left: 36px;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 32px;

      h4 {
        width: 100%;
        height: 60px;
        background: #f6f7fc;
        box-sizing: border-box;
        padding-left: 58px;
        padding-right: 40px;
        color: #474747;
        flex-shrink: 0;
        position: relative;
        @include flexBox;

        span {
          display: inline-block;
          width: 100%;
        }

        &::before {
          content: "\e62f";
          font-family: "iconfont";
          font-size: 30px;
          color: #d0cbe7;
          position: absolute;
          left: 18px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .detail-enter {
        width: 100%;
        height: 198px;
        padding: 30px 0;
        position: relative;
        @include flexBox(center);

        .mine,
        .school,
        .terrace {
          width: 153px;
          height: 100%;
          background: url("~@/assets/images/bg_mine.png");
          text-align: center;
          box-sizing: border-box;
          line-height: 24px;
          padding-top: 52px;

          .number {
            font-size: 24px;
            line-height: 36px;
            color: #6340c8;
          }

          .tip {
            color: #2a2a2a;
            margin-bottom: 30px;
          }

          .enter-link {
            color: #2c2c2c;

            &:hover {
              color: #6340c8;
              cursor: pointer;
            }
          }
        }

        .school {
          background: url("~@/assets/images/bg_school.png");
          margin: 0 4px;

          .number {
            color: #2ac293;
          }

          .enter-link {
            &:hover {
              color: #2ac293;
            }
          }
        }

        .terrace {
          background: url("~@/assets/images/bg_terrace.png");

          .number {
            color: #faaa1e;
          }

          .enter-link {
            &:hover {
              color: #faaa1e;
            }
          }
        }
      }

      &:hover {
        box-shadow: -5px 5px 27px -10px rgba(108, 78, 203, 0.24),
          5px 5px 27px -10px rgba(108, 78, 203, 0.24);
      }
    }
  }
}
</style>

<template>
  <section class="question-bank">
    <div class="page-head">
      <img class="bitmap" src="@assets/images/bitmap-head.png" alt />
      <div class="head-wrapper column">
        <breadcrumb />
        <div class="wrap-plate">
          <div class="search-form">
            <el-form inline :model="searchForm">
              <el-form-item>
                <el-select v-model="searchForm.grade" placeholder="请选择年级" filterable clearable>
                  <el-option v-for="item in gradeList" :key="item.grade_id" :label="item.grade_name"
                    :value="item.grade_id" />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="searchForm.semester" placeholder="请选择学期" clearable>
                  <el-option label="上学期" :value="1" />
                  <el-option label="下学期" :value="2" />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="searchForm.sysub_id" placeholder="请选择科目" filterable clearable @change="getTextBooks">
                  <el-option v-for="item in subjectList" :key="item.sysub_id" :label="item.sysub_name"
                    :value="item.sysub_id" />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="searchForm.sccou_id" placeholder="请选择教材版本" filterable clearable>
                  <el-option v-for="item in textbooks" :key="item.sccou_id" :label="item.sccou_version_name"
                    :value="item.sccou_id" />
                </el-select>
              </el-form-item>
            </el-form>
            <el-button type="custom_primary" size="medium" @click="searchData">查询</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="page-inner">
      <div class="questions-wrapper" :class="{ 'no-data': $isEmpty(tableData) }">
        <ul class="questions">
          <li class="questions-item" v-for="item in tableData" :key="item.sccou_id">
            <h4 class="bold">
              <span class="line-text--1st">{{ item.question_bank_name }}</span>
            </h4>
            <div class="detail-enter">
              <div class="mine">
                <p class="pf_bold number">{{ item.plque_ban_teachernum || 0 }}</p>
                <p class="tip">我的库共计题目数</p>
                <p class="enter-link" @click="targetQuestions(1, item.sccou_id)">进入&gt;</p>
              </div>
              <div class="school">
                <p class="pf_bold number">{{ item.plque_ban_schoolnum || 0 }}</p>
                <p class="tip">校本库共计题目数</p>
                <p class="enter-link" @click="targetQuestions(2, item.sccou_id)">进入&gt;</p>
              </div>
              <div class="terrace">
                <p class="pf_bold number">{{ item.plque_ban_platformnum || 0 }}</p>
                <p class="tip">平台库共计题目数</p>
                <p class="enter-link" @click="targetQuestions(3, item.sccou_id)">进入&gt;</p>
              </div>
            </div>
          </li>
        </ul>
        <div class="no-data--empty" v-if="$isEmpty(tableData)">
          <img src="@assets/images/no-data3.png" alt="">
          <p>暂无题库数据哦~</p>
        </div>
      </div>
      <!-- 分页控件 -->
      <div class="custom-foot">
        <customPagination :current="tablePage.pageIndex" :total="tablePage.total" :size="tablePage.pageSize"
          @pageChange="flippingPage" />
      </div>
    </div>
  </section>
</template>

<script>
import { $getQuesGroup } from "@api/homeworkExam";
import { $getSubTextbook } from "@api/common"
import { mapState } from "vuex";
import customPagination from "@comp/customPagination";
export default {
  name: "he_questionBank",
  components: { customPagination },
  computed: {
    ...mapState("common", ["gradeList", "subjectList"]),
  },
  data() {
    return {
      searchForm: {},
      textbooks: [],
      tableData: [], // 考试列表数据
      /* 表格分页参数 */
      tablePage: {
        pageIndex: 1,
        pageSize: 9,
        total: 0,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    /** 获取教材数据 */
    async getData() {
      let params = {
        ...this.searchForm,
        pageindex: this.tablePage.pageIndex,
      };
      let { data: res } = await $getQuesGroup(params, this.tablePage.pageSize);
      this.tablePage.total = res.allcount;
      this.tableData = res.data;
      this.$forceUpdate();
    },
    /** 获取教材版本列表 */
    async getTextBooks(id) {
      if (!id) {
        this.textbooks = [];
        delete this.searchForm.sccou_id;
        return;
      }
      let { data } = await $getSubTextbook(id);
      this.textbooks = [...data]
    },
    /** 翻页 */
    flippingPage(val) {
      this.tableData = [];
      this.tablePage.pageIndex = val;
      this.getData();
    },
    /** 搜索数据 */
    searchData() {
      this.tablePage.pageIndex = 1;
      this.tableData = [];
      this.getData();
    },
    /**
     * 跳转题库列表
     * @param {number} from 题目列表类型 1.我的  2.学校  3.平台
     * @param {number} id 题库id（教材版本id）
     */
    targetQuestions(from, id) {
      this.$router.push({
        name: "HE_QUESTIONLIST",
        params: { from, id },
      });
    },
  },
};
</script>